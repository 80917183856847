<template>
  <div>
    <base-select
      id="country"
      name="country"
      :options="countries"
      :selected="formData.country"
      :placeholder="$t('Country') + ' *'"
      v-model="formData.country"
      autocomplete="country"
      :validations="[{
        condition: $v.formData.country.$error && !$v.formData.country.required
      }]"
    />

    <transition name="toggle-slide">
      <base-select
        v-if="activeType && legaltype[activeType].length > 1 && (formData.country !== 'US' && formData.country !== 'CA')"
        id="legal_type"
        name="legal_type"
        :options="legaltype[activeType]"
        :selected="formData.legal_type"
        :placeholder="$t(countries.find(c => c.value === activeType).legaltypeLabel) + ' *'"
        v-model="formData.legal_type"
        :validations="[{
          condition: $v.formData.legal_type.$error && !$v.formData.legal_type.required
        }]"
      />
    </transition>

    <transition name="toggle-slide">
      <base-input
        v-if="formData.country !== 'US' && formData.country !== 'CA'"
        id="legal_id"
        ref="legal_id"
        type="text"
        name="legal_id"
        v-model="formData.legal_id"
        :placeholder="$t('ID') + ' *'"
        @keyup="formData.legal_exists = true"
        :validations="[{
          condition: $v.formData.legal_id.$error && !$v.formData.legal_id.required
        },
        {
          condition: !$v.formData.legal_id.reg && $v.formData.legal_id.$error
        },
        {
          condition: !formData.legal_exists
        },
        {
          condition: !formData.authorized_activity
        }]"
      />
    </transition>

    <transition name="toggle-slide">
      <ValidationMessages v-if="!formData.authorized_activity || !formData.legal_exists || !$v.formData.legal_id.reg || (errorNextStep || $v.formData.country.$error || $v.formData.legal_type.$error || $v.formData.legal_id.$error)"
        :validations="[{
          condition: errorNextStep || $v.formData.country.$error || $v.formData.legal_type.$error || $v.formData.legal_id.$error,
          text: $t('Please fill in the required fields')
        },
        {
          condition: !formData.legal_exists,
          text: $t('ID not found'),
        },
        {
          condition: !formData.authorized_activity,
          text: $t('Account creation is not possible for this activity'),
        },
        {
          condition: !$v.formData.legal_id.reg,
          text: $t('ID format is incorrect'),
        }]" id="legal_id" />
    </transition>
    <div class="require">{{ $t('* Required') }}</div>
  </div>
</template>

<script>
import BaseSelect from '../form/BaseSelect'
import BaseInput from '../form/BaseInput'
import { required, requiredIf } from 'vuelidate/lib/validators'
import ValidationMessages from '../form/ValidationMessages'
export let regexId = ''
export let validMobile = ''
export let validZipcode = ''
let val_country = 'United States'

export default {
  name: 'Step-1',
  props: ['formData', 'errorNextStep'],
  components: {
    BaseInput,
    BaseSelect,
    ValidationMessages
  },
  validations: {
    formData: {
      country: { required },
      legal_type: {
        required: requiredIf(function () {
          return (this.formData.country === this.activeType || (this.formData.country !== 'US' && this.formData.country !== 'CA'))
        })
      },
      legal_id: {
        required: requiredIf(function () {
          return (this.formData.country !== 'US' && this.formData.country !== 'CA')
        }),
        reg: function (v) {
          if (this.formData.country === 'US' || this.formData.country === 'CA') {
            return true
          } else {
            return new RegExp(regexId).test(v)
          }
        }
      }
    }
  },
  watch: {
    'errorNextStep' () {
      this.$v.$touch()
    },
    '$v.$invalid' () {
      this.$v.$touch()
      this.$emit('nextStepAuthorized', !this.$v.$invalid)
    },
    'formData.country' (v) {
      console.log("BEGIN formData.country")
      switch (v) {
        case "AT": val_country = 'Austria'; break;
        case "FR": val_country = 'France'; break;
        case "DE": val_country = 'Germany'; break;
        case "IT": val_country = 'Italy'; break;
        case "PT": val_country = 'Portugal'; break;
        case "GR": val_country = 'Greece'; break;
        case "ES": val_country = 'Spain'; break;
        case "CH": val_country = 'Switzerland'; break;
        case "PL": val_country = 'Poland'; break;
        case "CZ": val_country = 'Czech Republic'; break;
        case "SK": val_country = 'Slovakia'; break;
        case "HU": val_country = 'Hungary'; break;
        case "RO": val_country = 'Romania'; break;
        case "HR": val_country = 'Croatia'; break;
        case "RS": val_country = 'Serbia'; break;
        case "LT": val_country = 'Lithuania'; break;
        case "LV": val_country = 'Latvia'; break;
        case "EE": val_country = 'Estonia'; break;
        case "AU": val_country = 'Australia'; break;
        case "NZ": val_country = 'New Zealand'; break;
        case "CY": val_country = 'Cyprus'; break;
        case "LB": val_country = 'Lebanon'; break;
        case "SA": val_country = 'Saudi Arabia'; break;
        case "AE": val_country = 'United Arab Emirates'; break;
        case "US": val_country = 'United States'; break;
        case "CA": val_country = 'Canada'; break;
        default : val_country = 'United States';
      }
      window.tc_vars.env_country = val_country
      console.log("Call tC.container.reload() ")
      window.tC.container.reload()
      console.log("Call tC.event.all(this, {}) ")
      window.tC.event.all(this, {})
      console.log("END formData.country")
      this.activeType = this.countries.find(c => c.value === v).legaltype
      if (!this.activeType) {
        this.formData.legal_type = null
      } else {
        let legaltype_default = this.legaltype[this.activeType].find(c => c.default === true)
        this.formData.legal_type = legaltype_default ? legaltype_default.value : null
      }
      regexId = this.countries.find(c => c.value === v).regex
      validMobile = this.countries.find(c => c.value === v).validMobile
      validZipcode = this.countries.find(c => c.value === v).validZipcode
      this.awakeLegalId()
      this.selectCountry(v)
    },
    'formData.legal_type' (v) {
      if (this.formData.legal_type && this.activeType) {
        regexId = this.legaltype[this.activeType].find(c => c.value === v).regex
      }
      this.awakeLegalId()
    }
  },
  data() {
    return {
      activeType: false,
      legaltype: {
        'FR': [
          {
            value: 'regNo',
            label: 'SIRET',
            regex: /^[a-zA-Z0-9]{14}$/,
            default: true
          },
          {
            value: 'customerNo',
            label: this.$t('Somfy customer number'),
            regex: /^[a-zA-Z0-9]+$/
          }
        ],
        'ES': [
          {
            value: 'vatNo',
            label: this.$t('Tax Number'),
            regex: /^ES[a-zA-Z0-9][0-9]{7}[a-zA-Z0-9]$/,
            default: true
          },
          {
            value: 'customerNo',
            label: this.$t('Somfy customer number'),
            regex: /^[a-zA-Z0-9]+$/
          }
        ],
        'IT': [
          {
            value: 'vatNo',
            label: this.$t('Tax Number'),
            regex: /^IT[0-9]{11}$/,
            default: true
          },
          {
            value: 'customerNo',
            label: this.$t('Somfy customer number'),
            regex: /^[a-zA-Z0-9]+$/
          }
        ],
        'PT': [
          {
            value: 'vatNo',
            label: this.$t('Tax Number'),
            regex: /^PT[0-9]{9}$/,
            default: true
          },
          {
            value: 'customerNo',
            label: this.$t('Somfy customer number'),
            regex: /^[a-zA-Z0-9]+$/
          }
        ],
        'GR': [
          {
            value: 'vatNo',
            label: this.$t('Tax Number'),
            regex: /^EL[0-9]{9}$/,
            default: true
          },
          {
            value: 'customerNo',
            label: this.$t('Somfy customer number'),
            regex: /^[a-zA-Z0-9]+$/
          }
        ],
        'BG': [
          {
            value: 'vatNo',
            label: this.$t('Tax Number'),
            regex: /^BG[0-9]{9-10}$/,
            default: true
          },
          {
            value: 'customerNo',
            label: this.$t('Somfy customer number'),
            regex: /^[a-zA-Z0-9]+$/
          }
        ],
        'CH': [
          {
            value: 'regNo',
            label: this.$t('Tax Number'),
            regex: /^CHE[0-9]{9}$/
          },
          {
            value: 'customerNo',
            label: this.$t('Somfy customer number'),
            regex: /^[a-zA-Z0-9]+$/,
            default: true
          }
        ],
        'AT': [
          {
            value: 'vatNo',
            label: this.$t('Tax Number'),
            regex: /^ATU[0-9]{9}$/
          },
          {
            value: 'customerNo',
            label: this.$t('Somfy customer number'),
            regex: /^[a-zA-Z0-9]+$/,
            default: true
          }
        ],
        'DE': [
          {
            value: 'vatNo',
            label: this.$t('Tax Number'),
            regex: /^DE[0-9]{9}$/
          },
          {
            value: 'customerNo',
            label: this.$t('Somfy customer number'),
            regex: /^[a-zA-Z0-9]+$/,
            default: true
          }
        ],
        'PL': [
          {
            value: 'vatNo',
            label: this.$t('Tax Number'),
            regex: /^[0-9]{10}$/,
            default: true
          },
          {
            value: 'customerNo',
            label: this.$t('Somfy customer number'),
            regex: /^[a-zA-Z0-9]+$/
          }
        ],
        'CZ': [
          {
            value: 'customerNo',
            label: this.$t('Somfy customer number'),
            regex: /^[a-zA-Z0-9]+$/,
            default: true
          }
        ],
        'SK': [
          {
            value: 'customerNo',
            label: this.$t('Somfy customer number'),
            regex: /^[a-zA-Z0-9]+$/,
            default: true
          }
        ],
        'HU': [
          {
            value: 'vatNo',
            label: this.$t('Tax Number'),
            regex: /^[0-9]{8}-[0-9]{1}-[0-9]{2}$/,
            default: true
          },
          {
            value: 'customerNo',
            label: this.$t('Somfy customer number'),
            regex: /^[a-zA-Z0-9]+$/
          }
        ],
        'RO': [
          {
            value: 'vatNo',
            label: this.$t('Tax Number'),
            regex: /^RO[0-9]{6,10}$/,
            default: true
          },
          {
            value: 'customerNo',
            label: this.$t('Somfy customer number'),
            regex: /^[a-zA-Z0-9]+$/
          }
        ],
        'HR': [
          {
            value: 'vatNo',
            label: this.$t('Tax Number'),
            regex: /^[0-9]{9,11}$/,
            default: true
          },
          {
            value: 'customerNo',
            label: this.$t('Somfy customer number'),
            regex: /^[a-zA-Z0-9]+$/
          }
        ],
        'RS': [
          {
            value: 'vatNo',
            label: this.$t('Tax Number'),
            regex: /^[0-9]{9,11}$/,
            default: true
          },
          {
            value: 'customerNo',
            label: this.$t('Somfy customer number'),
            regex: /^[a-zA-Z0-9]+$/
          }
        ],
        'LT': [
          {
            value: 'regNo',
            label: this.$t('Tax Number'),
            regex: /^[0-9]{9}$/,
            default: true
          },
          {
            value: 'customerNo',
            label: this.$t('Somfy customer number'),
            regex: /^[a-zA-Z0-9]+$/
          }
        ],
        'LV': [
          {
            value: 'vatNo',
            label: this.$t('Tax Number'),
            regex: /^LV[0-9]{11}$/,
            default: true
          },
          {
            value: 'customerNo',
            label: this.$t('Somfy customer number'),
            regex: /^[a-zA-Z0-9]+$/
          }
        ],
        'EE': [
          {
            value: 'vatNo',
            label: this.$t('Tax Number'),
            regex: /^EE[0-9]{9}$/,
            default: true
          },
          {
            value: 'customerNo',
            label: this.$t('Somfy customer number'),
            regex: /^[a-zA-Z0-9]+$/
          }
        ],
        'AU': [
          {
            value: 'vatNo',
            label: this.$t('Tax Number'),
            regex: /^[0-9]{11}$/,
            default: true
          },
          {
            value: 'customerNo',
            label: this.$t('Somfy customer number'),
            regex: /^[a-zA-Z0-9]+$/
          }
        ],
        'NZ': [
          {
            value: 'regNo',
            label: this.$t('Company registration number'),
            regex: /^[0-9]{7}$/,
            default: true
          },
          {
            value: 'customerNo',
            label: this.$t('Somfy customer number'),
            regex: /^[a-zA-Z0-9]+$/
          }
        ],
        'CY': [
          {
            value: 'regNo',
            label: this.$t('Company registration number'),
            regex: /^[a-zA-Z0]{1}[0-9]{5,6}$/,
            default: true
          },
          {
            value: 'customerNo',
            label: this.$t('Somfy customer number'),
            regex: /^[a-zA-Z0-9]+$/
          }
        ],
        'LB': [
          {
            value: 'regNo',
            label: this.$t('Company registration number'),
            regex: /^[0-9]{5,7}$/,
            default: true
          },
          {
            value: 'customerNo',
            label: this.$t('Somfy customer number'),
            regex: /^[a-zA-Z0-9]+$/
          }
        ],
        'SA': [
          {
            value: 'regNo',
            label: this.$t('Company registration number'),
            regex: /^[0-9]{10}$/,
            default: true
          },
          {
            value: 'customerNo',
            label: this.$t('Somfy customer number'),
            regex: /^[a-zA-Z0-9]+$/
          }
        ],
        'AE': [
          {
            value: 'regNo',
            label: this.$t('Company registration number'),
            regex: /^[0-9]{5}$/,
            default: true
          },
          {
            value: 'customerNo',
            label: this.$t('Somfy customer number'),
            regex: /^[a-zA-Z0-9]+$/
          }
        ]
      },
      countries: [
        {
          value: 'AT',
          label: this.$t('Austria'),
          legaltype: 'AT',
          legaltypeLabel: this.$t('ID Type'),
          regex: /^ATU[0-9]{8}$/,
          validMobile: /^((00|\+\s?)43)\s?(6[56789][014689]){1}((\s|-)?\d{1}){7,8}$/,
          hasMiddlename: false,
          hasDepartment: false,
          hasMarketing: true,
          hasZipcode: true,
          languages: [
            {
              value: 'DE',
              label: 'Deutsch'
            }
          ]
        },
        {
          value: 'FR',
          label: this.$t('France'),
          legaltype: 'FR',
          legaltypeLabel: this.$t('ID Type'),
          regex: /^[a-zA-Z0-9]{14}$/,
          validMobile: /^((00|\+\s?)33)\s?(6|7){1}\d{8}$/,
          hasMiddlename: false,
          hasDepartment: true,
          hasMarketing: true,
          hasZipcode: true,
          languages: [
            {
              value: 'FR',
              label: 'Français'
            }
          ]
        },
        {
          value: 'DE',
          label: this.$t('Germany'),
          legaltype: 'DE',
          legaltypeLabel: this.$t('ID Type'),
          regex: /^DE[0-9]{9}$/,
          validMobile: /^(00|\+\s?)(\s?\d{1}){1,}$/,
          hasMiddlename: false,
          hasDepartment: false,
          hasMarketing: true,
          hasZipcode: true,
          languages: [
            {
              value: 'DE',
              label: 'Deutsch'
            }
          ]
        },
        {
          value: 'IT',
          label: this.$t('Italy'),
          legaltype: 'IT',
          legaltypeLabel: this.$t('ID Type'),
          regex: /^IT[0-9]{11}$/,
          validMobile: /^(\s?\d{1}){1,}$/,
          hasMiddlename: false,
          hasDepartment: true,
          hasMarketing: true,
          hasZipcode: true,
          languages: [
            {
              value: 'IT',
              label: 'Italiano'
            }
          ]
        },
        {
          value: 'PT',
          label: this.$t('Portugal'),
          legaltype: 'PT',
          legaltypeLabel: this.$t('ID Type'),
          regex: /^PT[0-9]{9}$/,
          validMobile: /^(2|3|8|9)\d{2}\s?\d{3}\s?\d{3}$/,
          hasMiddlename: false,
          hasDepartment: true,
          hasMarketing: true,
          hasZipcode: true,
          languages: [
            {
              value: 'PT',
              label: 'Portugese'
            }
          ]
        },
        {
          value: 'GR',
          label: this.$t('Greece'),
          legaltype: 'GR',
          legaltypeLabel: this.$t('ID Type'),
          regex: /^EL[0-9]{9}$/,
          regexLabel: 'EL',
          validMobile: /^(\s?\d{1}){1,}$/,
          hasMiddlename: false,
          hasDepartment: true,
          hasMarketing: true,
          hasZipcode: true,
          languages: [
            {
              value: 'EL',
              label: 'Greek'
            }
          ]
        },
        {
          value: 'ES',
          label: this.$t('Spain'),
          legaltype: 'ES',
          legaltypeLabel: this.$t('ID Type'),
          regex: /^ES[a-zA-Z0-9][0-9]{7}[a-zA-Z0-9]$/,
          validMobile: /^[6-9]\d{2}\s?\d{3}\s?\d{3}$/,
          hasMiddlename: false,
          hasDepartment: true,
          hasMarketing: true,
          hasZipcode: true,
          languages: [
            {
              value: 'ES',
              label: 'Spanish'
            }
          ]
        },
        {
          value: 'CH',
          label: this.$t('Switzerland'),
          legaltype: 'CH',
          legaltypeLabel: this.$t('ID Type'),
          regex: /^CHE[0-9]{9}$/,
          validMobile: /^((00|\+\s?)41)(\s?)(7)(\d{1})(\s?)(\d{3})(\s?)(\d{2})(\s?)(\d{2})|((00|\+\s?)423)(\s?)(\d{3})(\s?)(\d{2})(\s?)(\d{2})$/,
          hasMiddlename: false,
          hasDepartment: false,
          hasMarketing: true,
          hasZipcode: true,
          languages: [
            {
              value: 'FR',
              label: 'Français'
            },
            {
              value: 'DE',
              label: 'Deutsch'
            }
          ]
        },
        {
          value: 'PL',
          label: this.$t('Poland'),
          legaltype: 'PL',
          legaltypeLabel: this.$t('ID Type'),
          regex: /^[0-9]{10}$/,
          validMobile: /^[0-9]{6,14}$/,
          hasMiddlename: false,
          hasDepartment: true,
          hasMarketing: true,
          hasZipcode: true,
          languages: [
            {
              value: 'PL',
              label: 'Polish'
            }
          ]
        },
        {
          value: 'CZ',
          label: this.$t('Czech Republic'),
          legaltype: 'CZ',
          legaltypeLabel: this.$t('ID Type'),
          regex: /^[a-zA-Z0-9]+$/,
          validMobile: /^[0-9]{6,14}$/,
          hasMiddlename: false,
          hasDepartment: true,
          hasMarketing: true,
          hasZipcode: true,
          languages: [
            {
              value: 'CS',
              label: 'Czech'
            }
          ]
        },
        {
          value: 'SK',
          label: this.$t('Slovakia'),
          legaltype: 'SK',
          legaltypeLabel: this.$t('ID Type'),
          regex: /^[a-zA-Z0-9]+$/,
          validMobile: /^[0-9]{6,14}$/,
          hasMiddlename: false,
          hasDepartment: true,
          hasMarketing: true,
          hasZipcode: true,
          languages: [
            {
              value: 'SK',
              label: 'Slovak'
            }
          ]
        },
        {
          value: 'HU',
          label: this.$t('Hungary'),
          legaltype: 'HU',
          legaltypeLabel: this.$t('ID Type'),
          regex: /^[0-9]{8}-[0-9]{1}-[0-9]{2}$/,
          validMobile: /^[0-9]{6,14}$/,
          hasMiddlename: false,
          hasDepartment: true,
          hasMarketing: true,
          hasZipcode: true,
          languages: [
            {
              value: 'HU',
              label: 'Hungarian'
            }
          ]
        },
        {
          value: 'RO',
          label: this.$t('Romania'),
          legaltype: 'RO',
          legaltypeLabel: this.$t('ID Type'),
          regex: /^RO[0-9]{6,10}$/,
          validMobile: /^[0-9]{6,14}$/,
          hasMiddlename: false,
          hasDepartment: true,
          hasMarketing: true,
          hasZipcode: true,
          languages: [
            {
              value: 'RO',
              label: 'Romanian'
            }
          ]
        },
        {
          value: 'HR',
          label: this.$t('Croatia'),
          legaltype: 'HR',
          legaltypeLabel: this.$t('ID Type'),
          regex: /^[0-9]{9,11}$/,
          validMobile: /^[0-9]{6,14}$/,
          hasMiddlename: false,
          hasDepartment: true,
          hasMarketing: true,
          hasZipcode: true,
          languages: [
            {
              value: 'HR',
              label: 'Croatian'
            }
          ]
        },
        {
          value: 'RS',
          label: this.$t('Serbia'),
          legaltype: 'RS',
          legaltypeLabel: this.$t('ID Type'),
          regex: /^[0-9]{9,11}$/,
          validMobile: /^[0-9]{6,14}$/,
          hasMiddlename: false,
          hasDepartment: true,
          hasMarketing: true,
          languages: [
            {
              value: 'SR',
              label: 'Serbian'
            }
          ]
        },
        {
          value: 'LT',
          label: this.$t('Lithuania'),
          legaltype: 'LT',
          legaltypeLabel: this.$t('ID Type'),
          regex: /^[0-9]{9,12}$/,
          validMobile: /^[0-9]{6,14}$/,
          hasMiddlename: false,
          hasDepartment: true,
          hasMarketing: true,
          hasZipcode: true,
          languages: [
            {
              value: 'LT',
              label: 'Lithuanian'
            }
          ]
        },
        {
          value: 'LV',
          label: this.$t('Latvia'),
          legaltype: 'LV',
          legaltypeLabel: this.$t('ID Type'),
          regex: /^LV[0-9]{11}$/,
          validMobile: /^[0-9]{6,14}$/,
          validZipcode: /^LV-[0-9]{4}$/,
          hasMiddlename: false,
          hasDepartment: true,
          hasMarketing: true,
          hasZipcode: true,
          languages: [
            {
              value: 'LV',
              label: 'Latvian'
            }
          ]
        },
        {
          value: 'EE',
          label: this.$t('Estonia'),
          legaltype: 'EE',
          legaltypeLabel: this.$t('ID Type'),
          regex: /^EE[0-9]{9}$/,
          validMobile: /^[0-9]{6,14}$/,
          hasMiddlename: false,
          hasDepartment: true,
          hasMarketing: true,
          hasZipcode: true,
          languages: [
            {
              value: 'EE',
              label: 'Estonian'
            }
          ]
        },
        {
          value: 'AU',
          label: this.$t('Australia'),
          legaltype: 'AU',
          legaltypeLabel: this.$t('ID Type'),
          regex: /^[0-9]{11}$/,
          validMobile: /^[0-9]{6,14}$/,
          hasMiddlename: false,
          hasDepartment: true,
          hasMarketing: true,
          hasZipcode: true,
          languages: [
            {
              value: 'EN',
              label: 'English'
            }
          ]
        },
        {
          value: 'NZ',
          label: this.$t('New Zealand'),
          legaltype: 'NZ',
          legaltypeLabel: this.$t('ID Type'),
          regex: /^[0-9]{7}$/,
          validMobile: /^[0-9]{6,14}$/,
          hasMiddlename: false,
          hasDepartment: true,
          hasMarketing: true,
          hasZipcode: true,
          languages: [
            {
              value: 'EN',
              label: 'English'
            }
          ]
        },
        {
          value: 'CY',
          label: this.$t('Cyprus'),
          legaltype: 'CY',
          legaltypeLabel: this.$t('ID Type'),
          regex: /^[a-zA-Z]{1}[0-9]{5,6}$/,
          validMobile: /^[0-9]{6,14}$/,
          hasMiddlename: false,
          hasDepartment: true,
          hasMarketing: true,
          hasZipcode: false,
          languages: [
            {
              value: 'EN',
              label: 'English'
            }
          ]
        },
        {
          value: 'LB',
          label: this.$t('Lebanon'),
          legaltype: 'LB',
          legaltypeLabel: this.$t('ID Type'),
          regex: /^[0-9]{5,7}$/,
          validMobile: /^[0-9]{6,14}$/,
          hasMiddlename: false,
          hasDepartment: true,
          hasMarketing: true,
          hasZipcode: false,
          languages: [
            {
              value: 'EN',
              label: 'English'
            }
          ]
        },
        {
          value: 'SA',
          label: this.$t('Saudi Arabia'),
          legaltype: 'SA',
          legaltypeLabel: this.$t('ID Type'),
          regex: /^[0-9]{10}$/,
          validMobile: /^[+966]{4}[ ]?[0-9]{2}[ ]?[0-9]{7}$/,
          hasMiddlename: false,
          hasDepartment: true,
          hasMarketing: true,
          hasZipcode: false,
          languages: [
            {
              value: 'EN',
              label: 'English'
            }
          ]
        },
        {
          value: 'AE',
          label: this.$t('United Arab Emirates'),
          legaltype: 'AE',
          legaltypeLabel: this.$t('ID Type'),
          regex: /^[0-9]{5}$/,
          validMobile: /^[+971]{4}[ ]?[0-9]{1}[ ]?[0-9]{7}$/,
          hasMiddlename: false,
          hasDepartment: true,
          hasMarketing: true,
          hasZipcode: false,
          languages: [
            {
              value: 'EN',
              label: 'English'
            }
          ]
        },
        {
          value: 'US',
          label: this.$t('United States'),
          legaltype: null,
          legaltypeLabel: null,
          regex: /^[0-9]{5}$/,
          validMobile: /^(1-([0-9]{3})-([0-9]{3})-([0-9]{4})|((\+1)?\s?\(\d{3}\)\s?\d{3}-\d{4}))$/,
          validZipcode: /^\d{5}(?:[-\s]\d{4})?$/,
          hasMiddlename: false,
          hasDepartment: false,
          hasMarketing: true,
          hasZipcode: true,
          languages: [
            {
              value: 'EN',
              label: 'English'
            }
          ]
        },
        {
          value: 'CA',
          label: this.$t('Canada'),
          legaltype: null,
          legaltypeLabel: null,
          regex: /^[0-9]{5}$/,
          validMobile: /^(^(1)?( |-)?\(\d{3}\)( |-)?\d{3}( |-)?\d{4})|(1-([0-9]{3})-([0-9]{3})-([0-9]{4})|((\+1)?\s?\(\d{3}\)\s?\d{3}-\d{4}))$/,
          validZipcode: /^[ABCEGHJKLMNPRSTVXY]\d[A-Z]\s?\d[A-Z]\d?$/i,
          hasMiddlename: false,
          hasDepartment: false,
          hasMarketing: true,
          hasZipcode: true,
          languages: [
            {
              value: 'EN',
              label: 'English'
            }
          ]
        }
      ]
    }
  },
  mounted () {
    this.$emit('nextStepAuthorized', !this.$v.$invalid)
    if (this.formData.country) {
      this.activeType = this.countries.find(c => c.value === this.formData.country).legaltype
      if (this.activeType) {
        let legaltype_default = this.legaltype[this.activeType].find(c => c.default === true)
        this.formData.legal_type = legaltype_default ? legaltype_default.value : null
      }
    }
    if (this.formData.legal_type && this.activeType) {
      regexId = this.legaltype[this.activeType].find(c => c.value === this.formData.legal_type).regex
    }
  },
  methods: {
    selectCountry(value) {
      this.formData.countryObject = this.countries.find(country => country.value === value)
      if(this.formData.countryObject.languages && this.formData.countryObject.languages.length === 1) {
        this.formData.language = this.formData.countryObject.languages[0] ? this.formData.countryObject.languages[0].value : null
      }
    },
    awakeLegalId () {
      let tt = this.countries.find(c => c.value === this.formData.country).regexLabel
      if (typeof tt !== 'undefined') {
        if (this.formData.legal_type != 'customerNo') {
          this.$v.formData.legal_id.$model = tt
        } else {
          this.$v.formData.legal_id.$model = ' '
        }
      }
      if (this.$v.formData.legal_id.$model) {
        this.$v.formData.legal_id.$model = ' ' + this.$v.formData.legal_id.$model
        this.$v.formData.legal_id.$model = this.$v.formData.legal_id.$model.substring(1)
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
